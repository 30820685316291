"use client"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { httpBatchLink, httpLink, splitLink } from "@trpc/client"
import { createTRPCReact } from "@trpc/react-query"
import { type inferRouterInputs, type inferRouterOutputs } from "@trpc/server"
import { useState } from "react"
import superjson from "superjson"

import { toast } from "@/components/ui/Sonner"
import type { AppRouter } from "@/server/api/root"

export function getBaseUrl() {
  if (typeof window !== "undefined") {
    // browser should use relative path
    return ""
  }

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`
}

/** A set of type-safe react-query hooks for your tRPC API. */
export const api = createTRPCReact<AppRouter>({
  overrides: {
    useMutation: {
      async onSuccess(opts) {
        await opts.originalFn()
        await opts.queryClient.invalidateQueries()
      },
    },
  },
})

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>

export function TrpcProvider(props: { children: React.ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            onError: () => {
              toast.error("Něco se pokazilo.", {
                description: "Prosím, zkuste to znovu. Pokud problém přetrvává, kontaktujte nás.",
              })
            },
          },
        },
      }),
  )

  const url = `${getBaseUrl()}/api/trpc`
  const [trpcClient] = useState(() =>
    api.createClient({
      links: [
        splitLink({
          condition(op) {
            // check for context property `skipBatch`
            return !!op.context.skipBatch
          },
          // when condition is true, use normal request
          true: httpLink({ url, transformer: superjson }),
          // when condition is false, use batching
          false: httpBatchLink({ url, transformer: superjson, maxURLLength: 1024 }),
        }),
      ],
    }),
  )

  return (
    <api.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>
    </api.Provider>
  )
}
