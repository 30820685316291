import { useDisableNumberInputScroll } from "@deltagreen/react-library"
import * as React from "react"

import { cn } from "@/utils/tailwind"

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  const internalRef = useDisableNumberInputScroll(ref)

  return (
    <input
      type={type}
      className={cn(
        "flex h-14 w-full rounded-lg border border-gray-200 bg-white px-4 py-2 text-sm text-gray-600 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-sm placeholder:text-gray-400 focus-visible:border-persian-green-600 focus-visible:outline-none focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        className,
      )}
      ref={internalRef}
      role="textbox"
      {...props}
    />
  )
})
Input.displayName = "Input"

export { Input }
